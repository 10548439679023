<template>
    <router-view/>
</template>>

<script>
  export default {
    name: "ViewWrap"
  };
</script>

<style scoped>
</style>